import { BaseStatistics, TasksStatistics } from '@/modules/task/model'

import { BaseStatisticsResponse, TasksStatisticsResponse } from './taskApiInterfaces'

export const tasksStatisticsAdapter = (response: TasksStatisticsResponse) => {
  const tasksStatistics: TasksStatistics = {
    totalStats: response.totalStats,
    statsByTaskType: {
      'meeting-memo': response.statsByTaskType['meeting-memo'],
      'meeting-report': response.statsByTaskType['meeting-report'],
      'rr-enrich-contact': response.statsByTaskType['rr-enrich-contact'],
      'rr-new-contact': response.statsByTaskType['rr-new-contact'],
    },
  }

  return tasksStatistics
}

export const baseStatisticsAdapter = (response: BaseStatisticsResponse) => {
  const baseStatistics: BaseStatistics = {
    pendingTasksCount: response.pendingTasksCount,
    medianCompletionTimeMinutes: response.medianCompletionTimeMinutes,
    medianScore: response.medianScore,
  }

  return baseStatistics
}
