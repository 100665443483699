import cx from 'clsx'

import { TasksStatistics } from '@/modules/task/model'

import StatSection from './StatSection'

const TasksStatisticsView: React.FC<{ tasksStatistics: TasksStatistics; className?: string }> = ({
  tasksStatistics,
  className,
}) => {
  const { totalStats } = tasksStatistics
  const meetingMemoStats = tasksStatistics.statsByTaskType['meeting-memo']
  const meetingReportStats = tasksStatistics.statsByTaskType['meeting-report']
  const rrEnrichContactStats = tasksStatistics.statsByTaskType['rr-enrich-contact']
  const rrNewContactStats = tasksStatistics.statsByTaskType['rr-new-contact']

  const totalCount = totalStats.completed + totalStats.canceled + totalStats.failed

  const meetingMemoCount =
    meetingMemoStats.completed + meetingMemoStats.canceled + meetingMemoStats.failed
  const meetingReportCount =
    meetingReportStats.completed + meetingReportStats.canceled + meetingReportStats.failed

  const rrEnrichContactCount =
    rrEnrichContactStats.completed + rrEnrichContactStats.canceled + rrEnrichContactStats.failed
  const rrNewContactCount =
    rrNewContactStats.completed + rrNewContactStats.canceled + rrNewContactStats.failed

  return (
    <div className={cx('', className)}>
      <div className="max-w-[720px]">
        <StatSection
          className="mb-6"
          title="Tasks"
          total={totalCount}
          footerStats={[
            {
              label: 'Completed:',
              count: totalStats.completed,
            },
            {
              label: 'Canceled:',
              count: totalStats.canceled,
            },
            {
              label: 'Failed:',
              count: totalStats.failed,
            },
          ]}
          score={tasksStatistics.totalStats.medianScore ?? undefined}
        />

        <div className="grid grid-cols-2 gap-6 mobile:grid-cols-1">
          <StatSection
            title="Meeting Memo"
            titleLabel={<p className="rounded-md bg-[#BCA6FC] p-1.5 text-sm">outreach agent</p>}
            total={meetingMemoCount}
            footerStats={[
              {
                label: 'Completed:',
                count: meetingMemoStats.completed,
              },
              {
                label: 'Canceled:',
                count: meetingMemoStats.canceled,
              },
              {
                label: 'Failed:',
                count: meetingMemoStats.failed,
              },
            ]}
            // score={meetingMemoStats.medianScore ?? undefined}
          />
          <StatSection
            title="Meeting Report"
            titleLabel={<p className="rounded-md bg-[#BCA6FC] p-1.5 text-sm">outreach agent</p>}
            total={meetingReportCount}
            footerStats={[
              {
                label: 'Completed:',
                count: meetingReportStats.completed,
              },
              {
                label: 'Canceled:',
                count: meetingReportStats.canceled,
              },
              {
                label: 'Failed:',
                count: meetingReportStats.failed,
              },
            ]}
            // score={meetingReportStats.medianScore ?? undefined}
          />
          <StatSection
            title="New Contact"
            titleLabel={<p className="rounded-md bg-[#99FCBB] p-1.5 text-sm">records master</p>}
            total={rrNewContactCount}
            footerStats={[
              {
                label: 'Completed:',
                count: rrNewContactStats.completed,
              },
              {
                label: 'Canceled:',
                count: rrNewContactStats.canceled,
              },
              {
                label: 'Failed:',
                count: rrNewContactStats.failed,
              },
            ]}
            // score={rrNewContactStats.medianScore ?? undefined}
          />
          <StatSection
            title="Enrich Contact"
            titleLabel={<p className="rounded-md bg-[#99FCBB] p-1.5 text-sm">records master</p>}
            total={rrEnrichContactCount}
            footerStats={[
              {
                label: 'Completed:',
                count: rrEnrichContactStats.completed,
              },
              {
                label: 'Canceled:',
                count: rrEnrichContactStats.canceled,
              },
              {
                label: 'Failed:',
                count: rrEnrichContactStats.failed,
              },
            ]}
            // score={rrEnrichContactStats.medianScore ?? undefined}
          />
        </div>
      </div>
    </div>
  )
}

export default TasksStatisticsView
