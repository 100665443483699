import cx from 'clsx'

const StatSection: React.FC<{
  title: React.ReactNode
  titleLabel?: React.ReactNode
  total: React.ReactNode
  footerStats: Array<{ label: React.ReactNode; count: React.ReactNode }>
  score?: number
  className?: string
}> = ({ title, titleLabel, total, footerStats, score, className }) => {
  return (
    <section
      className={cx('rounded-[10px] border border-disabled p-2 pb-4 text-center', className)}
    >
      <div className="mb-6 flex flex-row items-center justify-center">
        <h3 className="pl-2 text-left text-lg">{title}</h3>
        {titleLabel ? (
          <>
            <div className="flex-grow" />
            {titleLabel}
          </>
        ) : null}
      </div>

      <p className="mb-6 text-4xl font-bold">{total}</p>
      <div className="flex flex-row items-center justify-center gap-4 text-secondary">
        {footerStats.map((stat, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="">
              <span className="mr-1">{stat.label}</span>
              <span className="font-bold">{stat.count}</span>
            </div>
          )
        })}
      </div>

      {score !== undefined ? (
        <div
          className={cx(
            'mt-4 inline-flex flex-row items-center gap-2 rounded-xl bg-primary-surface px-5 py-3',
            { 'text-disabled': !score },
          )}
        >
          <span>Median score:</span>
          <span className="font-bold">{score || '-'}</span>
        </div>
      ) : null}
    </section>
  )
}
export default StatSection
